.pam-block {
  display: flex;
  flex-direction: column;
  min-height: 500px;
  justify-content: center;
}

.pam-block .text {
  padding: 1em;
  font-size: 1.1em;
  width: 100%;
  align-self: center;
}

.pam-block .images {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  align-self: center;
}

.pam-block .images img {
  padding: 1em;
  box-sizing: border-box;
}

@media screen and (min-width: 800px) {
  .pam-block .text {
    padding: 2em;
    width: 55%;
  }

  .pam-block .images {
    flex-direction: row;
    padding: 2em;
    width: 45%;
  }

  .pam-block {
    flex-direction: row;
    min-height: 500px;
  }
}
