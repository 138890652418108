.nuestra-gente-block {
}

.mabel-container {
  display: flex;
  flex-direction: row;
}

.divider {
  height: 2px;
  background-color: #52217f;
  margin: 1em 0;
}

.mabel-image {
  height: 100%;
  width: 100%;
  max-width: 500px;
  max-height: 500px;
}

.mabel-info {
  /* align-self: flex-start; */
  max-height: none;
}

.mabel-title {
  color: #52217f;
}

.mabel-description {
  text-align: justify;
}

.team-info {
  flex-direction: column;
  justify-content: center;
  margin-bottom: 50px;
  padding: 1em;
}


.team-title {
  align-self: center;
  text-align: center;
  width: 90%;
}
.team-subtitle {
  align-self: center;
  text-align: center;
  color: #4c9d65;
  width: 90%;
}
.team-image {
  align-self: center;
  height: 100%;
  width: 90%;
}
@media screen and (min-width: 800px) {
  .team-info .text {
    margin: 0 2em;
  }
}
