.rx-block {
  width: 100%;
}

.rx-text {
  padding: 1em 3em;
  font-size: 1.25em;
}

.form-container {
  display: flex;
  justify-content: center;
  margin: 3em;
}

.file-input-container {
  padding: 1em;
  align-self: center;
}

.rx-type {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.rx-type p {
  padding: 1em 1em;
}

.doctor-block,
.patient-block {
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2em;
}

.doctor-icon,
.patient-icon {
  height: 300px;
  width: 300px;
  padding: 2em;
}

.app-store-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5em 1em;
}

img.app-store-icon {
  height: 50px;
  width: 50px;
  padding: 5px;
}

img.app-store-icon.wa {
  height: 60px;
  width: 60px;
  padding: 5px;
}

@media screen and (min-width: 800px) {
  .rx-type {
    flex-direction: row;
    padding: 1em 3em;
  }

  .doctor-block,
  .patient-block {
    display: flex;
    align-self: flex-start;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: 2em;
  }

  .doctor-icon,
  .patient-icon {
    height: 400px;
    width: 400px;
    padding: 2em;
  }
}

.form-field-names,
.form-field-phone-email,
.form-field-address,
.form-submit {
  display: flex;
  justify-content: center;
}

.form-field-address,
.form-field-date {
  flex: 1 1 auto;
  padding: 0 1.4em;
}

.form-field-date > div {
  flex-grow: 1;
  width: 100%;
}
